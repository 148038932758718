  /* CSS Reset */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%;
    font: inherit; */
    vertical-align: baseline;
    scroll-behavior: smooth;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @font-face {
    font-family: 'Modernism';
    src: url(./fonts/Sk-Modernist-Bold.otf) format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Modernism';
    src: url(./fonts/Sk-Modernist-Regular.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Space-Mono';
    src: url(./fonts/space-mono-latin-400-normal.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Space-Mono';
    src: url(./fonts/space-mono-latin-700-normal.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Work-Sans';
    src: url(./fonts/work-sans-latin-300-normal.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Work-Sans';
    src: url(./fonts/work-sans-latin-700-normal.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  body {
    line-height: 1;
    
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* CSS Reset ^*/


  .App {
    background-color: #F5F5F5;
  }


  .App-logo {
    min-height: 40vh;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }


  .App-header {
    text-align:center;
    min-height: 30vh;
    font-size: calc(10px + 2vmin);
    color: #f5f5f5;
  }

  .App-header img {
    margin:auto;
    width: 75%;
    background-size: cover;
  }

  .App-header button{
    color: black;
    background-color: #121212;
    font-size: 50px;
    width: calc(100%/3);
    border: 3px solid black;
    /*border-bottom:0;*/
  }

  /* NAV BAR  */

  .navbar {
    font-family: 'Work-Sans';
    position: fixed;
    z-index: 5;
  }

  #nav-custom .nav-link {
    color: #EEEEEE!important;
    font-size: 21px;
    padding: 12px;
    position:relative;
    text-decoration: none;
    letter-spacing: 0.5px;
    letter-spacing: 0.5px;
    margin-left: 5%;

  }
  #nav-custom .nav-link:after {
    color: ivory  !important;
    content: "";
    position: absolute;
    background-color: ivory;
    height: 2px;
    width: 0;
    left: 10;
    bottom: 10px;
    transition:0.3s;
  }

  #nav-custom .nav-link:hover::after {
    width: 75%;

  }
  /* /////// */

  #nav-custom .HashLink {
    color: #EEEEEE!important;
    font-size: 21px;
    padding: 12px;
    position:relative;
    text-decoration: none;
    letter-spacing: 0.5px;
    letter-spacing: 0.5px;
    margin-left: 5%;

  }
  #nav-custom .HashLink:after {
    color: ivory  !important;
    content: "";
    position: absolute;
    background-color: ivory;
    height: 2px;
    width: 0;
    left: 10;
    bottom: 10px;
    transition:0.3s;
  }

  #nav-custom .HashLink:hover::after {
    width: 75%;

  }

  .blue-button {
    background-color: ivory !important;
    color: black !important;
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important; 
    width: 120px !important;
    height: 40px !important;
    margin-left: 0px !important;
    transition: background-color 0.3s ease !important; 
  }
  .blue-button:hover {
    background-color: black !important; 
    color: #f5f5f5 !important;
  }


  /* /////////////// */
/* 
  WHITE NAV BAR */

  .black-button {
    background-color: #121212 !important;
    color: white !important;
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important; 
    width: 120px !important;
    height: 40px !important;
    margin-left: 0px !important;
    transition: background-color 0.3s ease !important; 
  }
  .black-button:hover {
    background-color: #3235FE !important; 
    color: white !important;
  }


  #nav-custom2 .nav-link {
    color: #121212!important;
    font-size: 21px;
    padding: 12px;
    position:relative;
    text-decoration: none;
    letter-spacing: 0.5px;
    letter-spacing: 0.5px;
    margin-left: 5%;

  }
  #nav-custom2 .nav-link:after {
    color: black  !important;
    content: "";
    position: absolute;
    background-color: black;
    height: 2px;
    width: 0;
    left: 10;
    bottom: 10px;
    transition:0.3s;
  }

  #nav-custom2 .nav-link:hover::after {
    width: 75%;

  }

  /* LANDING PAGE */

  .spacer {
    position: relative;
    z-index: 1;
    /* top: 0; 
    left: 0; */
    aspect-ratio: 960/300;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .layer1 {
      background-image: url(./graphics/wave-haikei.svg);
  }

  .layer2 {
    background-image: url(./graphics/landing-wave-2.svg);
  }

  .layer3 {
    background-image: url(./graphics/news-wave.svg);
  }
  .layer4 {
    background-image: url(./graphics/wave3.svg);
  }
  .layer5 {
    background-image: url(./graphics/wave4.svg);
  }
  .layer6 {
    background-image: url(./graphics/divider6.svg);
  }

  .hero-section {
    position:relative;
    background-color: #3235FE;
    /* background-color: #f5f5f5; */
    padding-bottom: -10%;
    z-index:2;
  }
  .hero-section2 {
    position:relative;
    background-color: #121212;
    height: 80vh;
    /* background-color: #f5f5f5; */
    z-index:2;
  }

  #slogan{
    /* background-color: #3235FE; */
    color:ivory;
    font-family: 'Work-Sans';
    width: 100%;
    font-size : 30px;
    text-align: center;
    margin-top: 10%;
    padding-bottom: 0%;
    padding-top:5%;
    padding-right: 5px;
  }

  #slogan h1 {
    font-weight: bold;
    font-size: 65px;
    margin-bottom: 1%;
  }
  #slogan p {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 35px;
  }

  .cards {
    margin-top: 5%;
    margin-bottom:15%;
    height: 500px;
  }

  .hook {
    text-align: left !important;
    color:white;
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .reason-text {
    margin-top: 50px;

    font-family: "Work-sans";
    
  }
  .reason-text h1,.solution-text h1 {
    font-weight: bold;
  }
  .reason-text p {
    width: 900px;

  }

  .solution {
    background-color: #121212;
    padding: 7% 0%;
  }

  .solution-section {
    background-color: #121212;
    color: white;
    display: flex;
    flex-direction: column; 
    /* margin-bottom: 15%; */
  }
  @media (min-width: 1200px) {
    .solution-section {
      flex-direction: row; 
    }
  }

    .features-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 5%;
    }
    
    .features-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 50px;
      /* max-width: 1100px; */
    }
    
    .feature-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

    }
    
    .text-content {
      max-width: 43%; 
      flex-grow: 1;
    }
    
    .image-placeholder {
      width:695px;
      height: 250px;
      background-color: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #666;
      margin-right: 5%;
      flex-grow: 0;

    }
    
    .image-placeholder::before {
      content: 'Image Placeholder';
    }
    
    .reason-button {
      background-color: #3235FE;
      color: white;
      border: none;
      padding: 0px 16px;
      font-size: 16px;
      border-radius: 30px;
      margin-bottom: 30px;
    }
    
    .title-reason {
      font-family: "Work-Sans";
      font-weight: bold;
      font-size: 48px;
      margin: 3% 0;
    }
    
    .text-reason {
      font-size: 28px;
      font-family: "Work-Sans";
    }
    
    .learn-more-btn {
      display: inline-block;
      margin-top: 30px;
      font-weight: bold;
      text-decoration: none;
      font-size: 22px;
      color: #000;
      border-bottom: 3px solid #000;
      padding-bottom: 5px;
    }
    
    .learn-more-btn:hover {
      color: #3235FE;
      border-color: #3235FE;
    }
    
    h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
    
    p {
      font-size: 22px;
      line-height: 1.6;
    }
  
  

  .solution-media {
    border-radius: 15px;
    height:550px;
    width:650px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .solution-text p {
    font-family: "Work-Sans";
    
    font-size: 25px;
    margin-right: 12%;
  }

  #reason-button {
    color: white;
    background-color: #3235FE;
    border-color: transparent;
    border-radius: 14px;
  }
  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
  
  .btn-light:hover {
    border-color: #3235FE;
  }
  
  .dropdown-menu {
    position: absolute;
    border-radius: 0.375rem;
  }
/* 
  .features-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

    /* .info
    {
      width: 100%;
      min-height: 60vh;
      font-size : 30px;
      color:ivory;
      background-color: #3235FE;
      margin-bottom: 50vh;
    } */

    /* CARDS SECTION */

  .card-section {
    padding-top: 10%;
    margin: 2%;
  }

  .info {
    background-color: #121212;
    color: #f5f5f5;
    padding-top: 7%;
    padding-bottom: 6%;
  }
  .info h2 {
    margin:3%;
  }
  .options-text
  {
    display:inline-block;
    margin-right: 10px;
  }

/* .dButton
  {
    width:250px;
    height:50px;
    font-size: 30px;
    border: 3px solid black;
    background-color: rgb(184, 179, 179);
    display:inline-block;
    margin-top: 10%;
    margin-bottom: 50%;
  } */
/* 
  .dropdown {
    position: relative;
    display: inline-block;
  } */
  /* .options{
    /* display: none;
    position: absolute;
    background-color: rgb(184, 179, 179);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  } */

  /* .options a {
    color: black;
  
    text-decoration: none;
    display: block;
  } */

  /* .options a:hover {
    background-color: white;} */

  /* .dropdown:hover .options {
    display: block;
  }  */

  .advertisement-add {
    text-align:center;
    color:white;
    margin: 50px;
  }

  .advertisement-add input {
    text-align:center;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing:border-box;
  }

  .advertisement-add-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    color: #fff;
    text-align: center;
    margin: 100px;
  }

  .advertisement-add-file button {
    text-align:center;
  }

  .advertisement-add-error {
    color:red;
    text-align:center;
  }

  .join-button {
    background-color: #3235fe !important ;
    color: white !important;
    border: none !important ;
    padding: 5px 10px ;
    border-radius: 5px !important ;
    font-size: 16px !important ;
    cursor: pointer !important; 
    width: 160px !important;
    margin-right: 10%;
    margin-left: -8%;
  }

  .join-button:hover {
    background-color: #5152ac !important;
  }

    .link-text {
      position: relative; 
      color: white; 
      text-decoration: none; 
      display: inline-block; 
      padding-bottom: 2px; 
  }

  .link-text::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0; 
      height: 2px;
      background-color: #3235fe; 
      transition: width 0.3s ease; 
  }

.link-text:hover::after {
    width: 100%; 
  }
  .info-section {
    margin-top: 20%;
    margin-bottom: 5%;
  }

  .info-detail{
    background-color: #121212;
    font-family: 'Work-Sans';
    color:#f5f5f5;
  }

  .pbox
  {
    background-color: #121212;
  }

  .pbox h1 {
    font-weight: bold;
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .pbox p {
    color: gainsboro; 
    font-size: 24px;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-right: 15%;
    padding-left:15%;
  }

  .lbox
  {
    background-color: #121212;
  }



    /* END OF LANDING PAGE */

    /* About page */
  .about-page {
    background-color: #F5F5F5 ;
  }

  .about-content {
    margin: 15%;
  }
  .about-us {
    background-color: #F5F5F5;
    color:black;
  }
  .about-us h2 {
    font-family: "Work-Sans";
    font-weight: bold;
    font-size: 36px;
  }
  .about-us p {
    font-family: "Work-Sans";
    margin-right: 25%;
    font-size: 32px;
    width: 600px;
  }
  .about-image img {
    /* padding-right: 100px; */
    margin-left: 25%;
    /* width: 1000px; */
    width: 110%;
    /* max-width: 100%; */
    height:auto;

  }


    /* END of About Page */

    /* NEWS PAGE */
    .news {
      background-color: #121212 !important;
      min-height: 100vh; 
    }

    .news-container {

    }
    
    .news-page {
      background-color: #121212 !important;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }
    
    .news-info {
      background-color: #121212 !important;
      margin: 5%;
      color: #f5f5f5;
      flex-grow: 1; 
    }

    .news-item {
      position:relative;
      display: flex;
      align-items: flex-start;
      color: #f5f5f5;
      margin-bottom: 3rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 20px;
      margin-bottom: 8%;
    }
    /* .news-item-divider {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 1px;
      background-color: #ffffff;
} */

    .news-item:last-child .news-item-divider {
      display: none;
    }
    
    .news-item-date {
      width: 25%;
      padding-right: 1rem;
      color: #f5f5f5;
      font-size: 0.875rem;
    }
    
    .news-item-content {
      width: 75%;
      font-size: 22px;
      font-family: 'Work-Sans';
    }
    
    .news-item-title {
      font-family: 'Work-Sans';
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
      color: #f5f5f5;
    }
    
    .news-item-media {
      margin-bottom: 1rem;
    }
    
    .news-item-media img,
    .news-item-media video {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 0.25rem;
    }
    
    .news-item-text {
      line-height: 1.5;
      color: #f5f5f5;
      font-family: "Modernism";
    }

    .controls {
      margin:5%;
    }
    .controls button {
      background-color: #f5f5f5 !important;
      font-size: 16px;
      border: none;
      color: #121212 !important;
    }
    .controls button:hover {
      background-color: #949393;
      border: none;
      color: #121212;
    }
    
    .pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* margin-top: 1rem; */
      

    }
    
    .pagination-text {
      color: #f5f5f5;
    }

    /* .control-button {
      background-color: #F5F5F5 !important;
    } */
    
    h1 {
      color: #f5f5f5;
    }

    .controls .right-icon {
      width: 1em;
      height: 1em + 5px;
      margin-left: 3px;
    }
    .controls .left-icon {
      width: 1em;
      height: 1em + 5px;
      margin-right: 3px;
    }

    @media (max-width: 768px) {
      .news-item {
        flex-direction: column;
      }
    
      .news-item-date,
      .news-item-content {
        width: 100%;
      }
    
      .news-item-date {
        margin-bottom: 0.5rem;
      }
    }
    

    .spacer.layer3 {
      margin-bottom: -1px;
    }
    


    /* END OF NEWS PAGE */


    /* Careers page */
    /* .careers{
      text-align:center;
      color:#121212;
    }
    .career-title {
      margin: 3%;
    }
    .career-title-info {
      margin-bottom: 10px;
      text-align: left;
      
    }
    .careers h1 {
      font-size: 46px;
    }
    .careers h4 {
      font-weight: normal;
    } */
    .careers {
      background-color: #f8f9fa;
      font-family: "Work-Sans"
    }
    
    .career-title {
      text-align: center;
      justify-content: center;
      height: 300px;
    }
    
    .career-title h2 {
      color: #121212;
      font-weight: bold;
    }
    
    .job-listing h3 {
      color: #333;
      font-weight: bold;
    }
    
    .job-listing p {
      color: #666;
    }

    .job-tags {
      pointer-events: none;
      background-color: #0000;
      border: 1.5px solid #121212;
      border-radius: 24px;
      /* width: 125px; */
      font-weight: bold;
      height: 35px;
      margin-right: 8px;
      padding: 0px 10px;
    }

    .lucide-clock,lucide-map-pin-house{
      width:20px;
      height:20px;
      margin-bottom: 3px;
      margin-right: 3px;
    }
    
    .badge {
      font-weight: normal;
      padding: 0.5em 1em;
    }
    
    .btn-outline-dark {
      border-radius: 0;
      padding: 0.5em 1.5em;
    }
    
    .border-bottom {
      border-color: #dee2e6 !important;
    }


    /* FAQ page */
  .faq{
    text-align: center;
    /* color: white; */
  }

  .faq-title{
    /* margin-top: 10vh;
    font-size: 4vh;
    margin-bottom: 15vh; */
    text-align: left;
    margin: 5% 0%;
    margin-left: 9%;

    /* margin:10%; */
  }
  .faq-title h1{
    font-size: 48px;
  }

  .questions-block summary {
    color:#000 ;
  }

  .accordion-button {
    background-color: #f5f5f5 !important;
  }

  .questions-block{
      color: #121212; 
      color: #f5f5f5;
      font-size: 3.5vh;
      margin-bottom: 30vh;
      padding:25%;
  }
  
  .questions-block p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  .questions-block details{
      padding-bottom: 5vh;
      padding-top: 5vh;
      padding-right: 5vw;
      padding-left: 5vw;
  }

  .questions-block p{
    padding-top: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .faq-hero {
    background-color:white;
    padding: 200px 20px;
  }
  .faq-hero h1 {
    font-family: "Work-Sans";
    font-weight: bold;
    color: #121212;
  }
  .faq-hero p {
    padding-top: 25px;
    font-family: "Modernism";
    color: #121212;
  }
  .faq-hero h4 {
    padding-bottom: 25px;
  }
  .faq-accordion {
    margin: 75px;
  }
  .faq p {
    font-size:20px;
    color:gray;
  }
  .faq-header header  {
    background-color: #F5F5F5 !important;
    color: #121212;
  }
  .accordion-container .accordion .accordion-header {
    font-family: "Work-Sans";
    font-weight: bold !important;
    color: #121212 ;
  }
  .accordion-container .accordion .accordion-body {
    font-family: "Modernism";
    color: grey ;
  }
  .accordion-container .accordion h2 {
    font-size: 24px;
    font-family: "Work-Sans";
    font-weight: bold;
    color: #121212 ;
  }
  .accordion-container .accordion {
    margin:3%;
  }




  /* END OF FAQ PAGE */

  /* investors */

  .investors {
    text-align: center;
    color: black;
    background-color: #f5f5f5;
  }

  .investors-title {
    margin-top: 10vh;
    font-size: 4vh;
    margin-bottom: 15vh;
    
  }

  .investors-body {

    margin-right: 10%;
    margin-left: 10%;
  }

  .why-us {
  
  }

  .investors-body p{



  }

  .content-block {
    background-color: #3235FE;
    text-align: center;
    color: white;
    font-size: 3.5vh;
    margin-bottom: 30vh;
    padding-top: 5%;
  }

  .investors-links
    {
      display:inline-block;
      background-color: rgb(60, 65, 66);
      font-size: 3.5vh;
      margin-bottom: 15vh;
    }

  .diagram {
    padding-bottom: 5vh;
    padding-top: 5vh;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  /* Survey */

  .Survey {
    text-align:center;
  }

  .survey-description {
    text-align: center;
    color: #fff;
  }

  .survey-description h1{
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .survey-questions {
    grid-gap: 20px;
    color: #fff;
    text-align: left;
    grid-template-columns: 1fr 1fr;
    margin: 100px;
    display: flex;
    flex-direction:column;
  }

  .Survey savebutton button {
    display:flex;
    text-align: right;
    background-color: gray;
    justify-content: center;
    align-items:center;
    font-size: 20px;
    position: absolute;
  }

  /* Survey Confirmation */
  .survey-confirmation{
    text-align: center;
  }

  .survey-confirmation-description {
    text-align: center;
    margin: 80px 50px;
    color:white;
  }

  .survey-confirmation-description h2{
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  /* Dropdown selection */

  .custom-select {
    min-width: 350px;
    position: relative;
  }

  .custom-select select {
    appearance: none;
    width: 100%;
    font-size: 1.15rem;
    padding: 0.675em 6em 0.675em 1em;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 0.25rem;
    color: #000;
    cursor: pointer;
  }

  .custom-select::before,
  .custom-select::after {
    --size: 0.3rem;
    content: "";
    position: absolute;
    right: 1rem;
    pointer-events: none;
  }

  .custom-select::before {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid black;
    top: 40%;
  }

  .custom-select::after {
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid black;
    top: 55%;
  }

  * {
    box-sizing: border-box;
  }

  /* consent popup css */
  .consentpopup {
    text-align:center;
    color:white;
    margin: 150px 50px;
  }

  .consentpopup h1{
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .consentpopup2 h2{
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .consent-button-selections {
    display:grid;
    grid-template-columns: 1fr 1fr;
    column-gap:30px;
    text-align:center;
  }
  .consent-reject-button {
    background-color:rgb(255, 63, 63);
    padding: 30px 50px;
    color: white;
    border:none;
  }

  .consent-accept-button {
    background-color:rgb(16, 189, 16);
    padding: 30px 50px;
    color: white;
    border:none;
  }

  /* websites css*/

  .websites {
    text-align: center;
  }

  .websites-title {
    margin-top: 10vh;
    font-size: 4vh;
    margin-bottom: 15vh;
    color: white;
  }

  .websites-title p {
    margin-top: 3vh;
    color: white;
    font-size: 2vh;
    margin-bottom: 5vh;
  }

  .content-block {
    text-align: center;
    margin-bottom: 30vh;
  }

  .website-entry {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .website-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    background-color: yellow;
  }

  .website-description {
    text-align: left;
  }

  .website-description a {
    font-size: 2vh;
    color: blue;
    text-decoration: underline;
  }

  .website-description p {
    margin-top: 3vh;
    font-size: 2vh;
  }

  /* Introduction */

  .introduction{
    text-align: center;
    color: white;
  }

  .introduction-title{
    margin-top: 10vh;
    font-size: 4vh;
    margin-bottom: 10vh;
  }

  .introduction-main {
    text-align: center;
    color: white;
    font-size: 3.5vh;
    margin-bottom: 15vh;
  }

  .diagram-or-video {
    border: 1px solid #fff;
    margin-top: 10vh;
    text-align: center;
    padding: 20px;
    margin: 10px auto;
    margin-bottom: 8vh;
    width: 60%;
    background-color: rgb(60, 65, 66);
    color: white;
  }

  .introduction-text {
    text-align: center;
    margin: 10px auto;
    color: white;
    margin-bottom: 10vh;
    width: 60%;
  }

  /* AdvertiserSignup page */
  .advertiser-signup{
    text-align: center;
    color: black;
  }

  .advertiser-signup p{
    font-size: 5vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 7vw;
    padding-left: 7vw;
  }

  .advertiser-signup-form{
    text-align: left;
  }

  .advertiser-signup input{
    margin-right: 10vw;
    margin-bottom: 5vh;
  }

  .signup-textbox{
    margin-left: 10vw;
    text-align: center;
    width: 70vw;
    min-width: 30vw;
    max-width: 55vw;
    min-height: 5vh;
    font-size: 3vh;
  }

  .signup-textbox.valid {
    outline: 2px solid green;
  }

  .signup-textbox.invalid {
    outline: 2px solid red;
  }

  .signup-submit{
    margin-top: 5vh;
    text-align: center;
    min-width: 20vw;
    min-height: 5vh;
    font-size: 3vh;
    margin-left: 7vw;
  }

  .advertiser-signup-form label{
    margin-left: 10vw;
    font-size: 5vh;
    vertical-align: center;
  }

  .signup-toggle-password{
    font-size: 3vh;
    margin-left: -8vw;
  }

  /* EmailConfirmation page */
  .email-confirmation{
    text-align: center;
    color: white;
  }

  .email-confirmation p{
    font-size: 5vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 7vw;
    padding-left: 7vw;
  }

  .email-confirmation-form{
    text-align: left;
  }

  .email-confirmation input{
    margin-right: 10vw;
  }

  .email-confirmation label{
    margin-left: 0vw;
    font-size: 4vh;
    vertical-align: center;
  }

  .new-code-button{
    margin-top: 5vh;
    text-align: center;
    min-width: 20vw;
    min-height: 5vh;
    font-size: 3vh;
  }

  .confirmation-code-submit{
    margin-top: 5vh;
    text-align: center;
    min-width: 15vw;
    min-height: 5vh;
    font-size: 3vh;
  }

  .buttonArea {
    margin-top: 5vh;
    text-align: center;
    min-width: 15vw;
    min-height: 5vh;
    font-size: 3vh;
  }

  .email-confirmation-form p{
    margin-top: 5px;
    text-align: center;
  }

  @media (max-width: 480px) {
    .signup-textbox{
      margin-left: 10vw;
      text-align: center;
      width: 45vw;
      min-height: 5vh;
      font-size: 2vh;
    }

    .email-confirmation label{
      margin-left: 0vw;
      font-size: 2vh;
      vertical-align: center;
    }

  }

  /*confirmation-login*/

  .confirmation-login{
    text-align: center;
  }

  .confirmation-login h1{
    font-size: 3vh;
    font-weight: bold;
  }
  /* Introduction */

  .introduction{
    text-align: center;
    color: white;
  }

  .introduction-title{
    margin-top: 10vh;
    font-size: 4vh;
    margin-bottom: 10vh;
  }

  .introduction-main {
    text-align: center;
    color: white;
    font-size: 3.5vh;
    margin-bottom: 15vh;
  }

  .diagram-or-video {
    border: 1px solid #fff;
    margin-top: 10vh;
    text-align: center;
    padding: 20px;
    margin: 10px auto;
    margin-bottom: 8vh;
    width: 60%;
    background-color: rgb(60, 65, 66);
    color: white;
  }

  .introduction-text {
    text-align: center;
    margin: 10px auto;
    color: white;
    margin-bottom: 10vh;
    width: 60%;
  }

  /* PublisherHome page*/
  .PublisherHome{
    text-align: center;
    color: white;
    font-size: 5vh;
  }

  .PublisherHome-websites{
    text-align: center;
    min-width: 60vw;
    margin-bottom: 5vh;
  }

  .PublisherHome table {
    margin-left: 15vw;
    margin-top: 5vh;
    text-align: center;
    font-size: 3vh;
    border-collapse: collapse;
    min-width: 60vw;
  }

  .PublisherHome th, td {
    border: 3px solid white;
    padding: 6px;
  }

  .publisher-button{
    margin-top: 5vh;
    text-align: center;
    min-width: 15vw;
    min-height: 5vh;
    font-size: 3vh;
    margin-bottom: 10vh;
  }

  .popup {
    position: relative;
    display: inline-block;
  }

  .popup .popup-text {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  .popup:hover .popup-text {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 480px) {
    .PublisherHome {
      font-size: 3vh;
    }

    .publisher-button{
      font-size: 2vh;
    }

    .PublisherHome table {
      margin-left: 2vw; 
      min-width: 50vw; 
      font-size: 2vh;
    }
  }



  /* Login CSS */

 .login-page {
  font-family: "Work-Sans";
 }

login-container h2.text-left.login-title {

  margin: 15% 0;
  /* color: green; */
}

.login-prompt p {
  font-size: 16px;
}
.create-account {
  margin: 5% 0;
  font-size: 16px;
}

  .login-input {
  /* font-size: 5vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-right: 7vw;
  padding-left: 7vw;
  width: 250px; */
}

.login-error {
  color:red;
} 

.login-title {
  margin:8%;
  font-weight: bold;
  font-family: "Work-Sans";
}
.login-title-two {
  margin: 8% 0px; 
  font-family: "Work-Sans";
  font-weight: normal;
}


  /* Campaign Add CSS */

.campaign-add {
  text-align:center;
  color:white;
}

/* Redirect Pages */

.advertiser-redirect {
  color:white;
  text-align:center;
  margin-top:100px;
  margin-bottom:75px;
}

.advertiser-redirect-message {
  font-size:2.5rem;
  padding-left:10%;
  padding-right:10%;
  margin-bottom:75px;
}

.footer {
  background-color: white;
  color: black;
  padding: 20px 0;
}

.footer-logo {
  font-family: 'Space-mono', monospace;
  font-size: 24px;
  font-weight: bold;
  color: black;

}

.footer .navbar .nav-link {
  color: #333; 
}

.footer .navbar .nav-link:hover {
  color: #000;  
}
.footer-contact {
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left;
}

.footer-nav .nav-link {
  color: #212529;
  padding: 0.5rem 1rem;
}

.footer-right .btn {
  border-radius: 20px;
  padding: 0.375rem 1rem;
}

@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-nav, .footer-right {
    margin-top: 1rem;
  }
}
/* 
Get Started Page */

.page {
  height:100vh;
}
.page .body2 {
  width: 95%;
  text-align: center;
}


